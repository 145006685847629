<template>
  <div>
    <v-expansion-panels v-model="expansionPanels">
      <v-expansion-panel
        v-for="(space, spaceIndex) in spaces"
        :key="'panel-' + spaceIndex"
      >
        <v-expansion-panel-header expand-icon="mdi-menu-down" v-if="space.name">
          <template v-slot:actions>
            <v-btn
              fab
              max-width="24"
              max-height="24"
              depressed
              x-small
              color="#B3B3B3"
              ><v-icon small color="white">$expand</v-icon></v-btn
            >
          </template>
          <div class="d-flex justify-space-between">
            <div class="f-17 text--primary">{{ space.name }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat color="white">
            <v-card-text class="pb-0 mb-0">
              <v-row>
                <v-col cols="12" class="py-1 my-1" v-if="space.category">
                  <div class="body-2 font-weight-bold text-uppercase">
                    Category
                  </div>
                  <div class="black--text body-1">{{ space.category }}</div>
                </v-col>
                <v-col
                  cols="12"
                  class="py-1 my-1"
                  v-if="space.name !== '' && space.name !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Room Name
                  </div>
                  <div class="black--text body-1">{{ space.name }}</div>
                </v-col>
                <v-col
                  cols="12"
                  class="py-1 my-1"
                  v-if="space.description !== '' && space.description !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Description
                  </div>
                  <div class="black--text body-1">{{ space.description }}</div>
                </v-col>
                <v-col
                  cols="12"
                  class="py-1 my-1"
                  v-if="
                    space.acoustic_description !== '' &&
                    space.acoustic_description !== null
                  "
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Acoustic Description
                  </div>
                  <div class="black--text body-1">
                    {{ space.acoustic_description }}
                  </div>
                </v-col>
                <v-col cols="6" class="py-1 my-1" v-if="space.size">
                  <div class="body-2 font-weight-bold text-uppercase">
                    Dimensions
                  </div>
                  <div class="black--text body-1">
                    <span v-if="space.width && space.height">
                      {{ space.width | commaSeparator }}x{{
                        space.height | commaSeparator
                      }}
                      m (
                    </span>
                    {{ parseFloat(space.size) | commaSeparator }}
                    <span v-if="space.width && space.height">)</span> sqm
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="space.height !== '' && space.height !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Height
                  </div>
                  <div class="black--text body-1">
                    {{ space.height | commaSeparator }} m
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="
                    space.floor_number !== '' && space.floor_number !== null
                  "
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Floor Nr
                  </div>
                  <div class="black--text body-1">{{ space.floor_number }}</div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="space.floor_type !== '' && space.floor_type !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Floor Type
                  </div>
                  <div class="black--text body-1">{{ space.floor_type }}</div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="space.is_daylight !== '' && space.is_daylight !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Daylight
                  </div>
                  <div class="black--text body-1">
                    {{ space.is_daylight === 1 ? "YES" : "NO" }}
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="space.is_daylight !== '' && space.is_daylight !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Windows Dimensions
                  </div>
                  <div class="black--text body-1">
                    {{
                      space.is_daylight === 1
                        ? space.window_length !== null &&
                          space.window_width !== null
                          ? space.window_length +
                            "x" +
                            space.window_width +
                            " m"
                          : "0"
                        : "0"
                    }}
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="
                    space.has_parking_space !== '' &&
                    space.has_parking_space !== null
                  "
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Parking Space
                  </div>
                  <div class="black--text body-1">
                    {{
                      space.has_parking_space === 1 ? space.parking_slots : 0
                    }}
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-1 my-1"
                  v-if="space.is_outdoor !== '' && space.is_outdoor !== null"
                >
                  <div class="body-2 font-weight-bold text-uppercase">
                    Outdoor
                  </div>
                  <div class="black--text body-1">
                    {{ space.is_outdoor === 1 ? "YES" : "NO" }}
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  class="py-1 my-1"
                  v-if="
                    (space.standing_max_capacity !== '' &&
                      space.standing_max_capacity !== null) ||
                    (space.u_shape_max_capacity !== '' &&
                      space.u_shape_max_capacity !== null) ||
                    (space.cocktail_rounds_max_capacity !== '' &&
                      space.cocktail_rounds_max_capacity !== null) ||
                    (space.banquet_rounds_max_capacity !== '' &&
                      space.banquet_rounds_max_capacity !== null) ||
                    (space.theater_max_capacity !== '' &&
                      space.theater_max_capacity !== null) ||
                    (space.classroom_max_capacity !== '' &&
                      space.classroom_max_capacity !== null) ||
                    (space.boardroom_max_capacity !== '' &&
                      space.boardroom_max_capacity !== null)
                  "
                >
                  <div class="mb-2 body-2 font-weight-bold text-uppercase">
                    Sitting Capacities
                  </div>
                  <div class="flex-wrap d-flex ml-n2">
                    <v-card
                      class="ma-2 d-flex"
                      width="112"
                      outlined
                      v-if="
                        space.standing_max_capacity !== '' &&
                        space.standing_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div>
                          <v-icon color="primary">mli-standing-capacity</v-icon>
                        </div>
                        <div>Standing Capacity</div>
                        <div class="subtitle-2">
                          {{ space.standing_max_capacity | commaSeparator }}
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="ma-2 d-flex"
                      width="112"
                      outlined
                      v-if="
                        space.u_shape_max_capacity !== '' &&
                        space.u_shape_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div><v-icon color="primary">mli-u-shape</v-icon></div>
                        <div>U-Shape</div>
                        <div class="subtitle-2">
                          {{ space.u_shape_max_capacity | commaSeparator }}
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="ma-2 d-flex"
                      width="112"
                      outlined
                      v-if="
                        space.cocktail_rounds_max_capacity !== '' &&
                        space.cocktail_rounds_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div>
                          <v-icon color="primary">mli-cocktail-rounds</v-icon>
                        </div>
                        <div>Cocktail rounds</div>
                        <div class="subtitle-2">
                          {{
                            space.cocktail_rounds_max_capacity | commaSeparator
                          }}
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="ma-2 f-flex"
                      width="112"
                      outlined
                      v-if="
                        space.banquet_rounds_max_capacity !== '' &&
                        space.banquet_rounds_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div>
                          <v-icon color="primary">mli-banquet-rounds</v-icon>
                        </div>
                        <div>Banquet rounds</div>
                        <div class="subtitle-2">
                          {{
                            space.banquet_rounds_max_capacity | commaSeparator
                          }}
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="ma-2 d-flex"
                      width="112"
                      outlined
                      v-if="
                        space.theater_max_capacity !== '' &&
                        space.theater_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div><v-icon color="primary">mli-theater</v-icon></div>
                        <div>Theater</div>
                        <div class="subtitle-2">
                          {{ space.theater_max_capacity | commaSeparator }}
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="d-flex ma-2"
                      width="112"
                      outlined
                      v-if="
                        space.classroom_max_capacity !== '' &&
                        space.classroom_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div>
                          <v-icon color="primary">mli-classroom</v-icon>
                        </div>
                        <div>Classroom</div>
                        <div class="subtitle-2">
                          {{ space.classroom_max_capacity | commaSeparator }}
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="ma-2 d-flex"
                      width="112"
                      outlined
                      v-if="
                        space.boardroom_max_capacity !== '' &&
                        space.boardroom_max_capacity !== null
                      "
                    >
                      <v-card-text
                        class="my-auto text-center body-1 text--primary"
                      >
                        <div>
                          <v-icon color="primary">mli-boardroom</v-icon>
                        </div>
                        <div class="my-1">Boardroom</div>
                        <div class="subtitle-2">
                          {{ space.boardroom_max_capacity | commaSeparator }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  class="py-1 my-1"
                  v-if="space.amenities.length > 0"
                >
                  <div class="mb-2 body-2 font-weight-bold text-uppercase">
                    Amenities
                  </div>
                  <div>
                    <v-chip
                      color="#A7A7A7"
                      small
                      class="px-2 py-0 mx-1 white--text"
                      label
                      v-for="amenity in space.amenities"
                      :key="amenity"
                      >{{ amenity }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: ["spaces"],
  data: () => ({
    expansionPanels: null,
  }),
  methods: {
    stringifyNumber(n) {
      let special = [
        "zeroth",
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "ninth",
        "tenth",
        "eleventh",
        "twelfth",
        "thirteenth",
        "fourteenth",
        "fifteenth",
        "sixteenth",
        "seventeenth",
        "eighteenth",
        "nineteenth",
      ];
      let deca = [
        "twent",
        "thirt",
        "fort",
        "fift",
        "sixt",
        "sevent",
        "eight",
        "ninet",
      ];
      if (n < 20) return this.capitalizeFirstLetter(special[n]);
      if (n % 10 === 0)
        return this.capitalizeFirstLetter(
          deca[Math.floor(n / 10) - 2] + "ieth"
        );
      return this.capitalizeFirstLetter(
        deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10]
      );
    },
    capitalizeFirstLetter(string = "") {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
<style scoped>
.v-expansion-panel {
  background-color: #f9f9f9 !important;
}
.theme--light.v-card {
  background-color: #f9f9f9 !important;
}
</style>