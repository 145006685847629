var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{model:{value:(_vm.expansionPanels),callback:function ($$v) {_vm.expansionPanels=$$v},expression:"expansionPanels"}},_vm._l((_vm.spaces),function(space,spaceIndex){return _c('v-expansion-panel',{key:'panel-' + spaceIndex},[(space.name)?_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"fab":"","max-width":"24","max-height":"24","depressed":"","x-small":"","color":"#B3B3B3"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("$expand")])],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"f-17 text--primary"},[_vm._v(_vm._s(space.name))])])]):_vm._e(),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-text',{staticClass:"pb-0 mb-0"},[_c('v-row',[(space.category)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Category ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(_vm._s(space.category))])]):_vm._e(),(space.name !== '' && space.name !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Room Name ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(_vm._s(space.name))])]):_vm._e(),(space.description !== '' && space.description !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Description ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(_vm._s(space.description))])]):_vm._e(),(
                  space.acoustic_description !== '' &&
                  space.acoustic_description !== null
                )?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Acoustic Description ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(" "+_vm._s(space.acoustic_description)+" ")])]):_vm._e(),(space.size)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Dimensions ")]),_c('div',{staticClass:"black--text body-1"},[(space.width && space.height)?_c('span',[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.width))+"x"+_vm._s(_vm._f("commaSeparator")(space.height))+" m ( ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("commaSeparator")(parseFloat(space.size)))+" "),(space.width && space.height)?_c('span',[_vm._v(")")]):_vm._e(),_vm._v(" sqm ")])]):_vm._e(),(space.height !== '' && space.height !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Height ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.height))+" m ")])]):_vm._e(),(
                  space.floor_number !== '' && space.floor_number !== null
                )?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Floor Nr ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(_vm._s(space.floor_number))])]):_vm._e(),(space.floor_type !== '' && space.floor_type !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Floor Type ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(_vm._s(space.floor_type))])]):_vm._e(),(space.is_daylight !== '' && space.is_daylight !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Daylight ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(" "+_vm._s(space.is_daylight === 1 ? "YES" : "NO")+" ")])]):_vm._e(),(space.is_daylight !== '' && space.is_daylight !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Windows Dimensions ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(" "+_vm._s(space.is_daylight === 1 ? space.window_length !== null && space.window_width !== null ? space.window_length + "x" + space.window_width + " m" : "0" : "0")+" ")])]):_vm._e(),(
                  space.has_parking_space !== '' &&
                  space.has_parking_space !== null
                )?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Parking Space ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(" "+_vm._s(space.has_parking_space === 1 ? space.parking_slots : 0)+" ")])]):_vm._e(),(space.is_outdoor !== '' && space.is_outdoor !== null)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"body-2 font-weight-bold text-uppercase"},[_vm._v(" Outdoor ")]),_c('div',{staticClass:"black--text body-1"},[_vm._v(" "+_vm._s(space.is_outdoor === 1 ? "YES" : "NO")+" ")])]):_vm._e(),(
                  (space.standing_max_capacity !== '' &&
                    space.standing_max_capacity !== null) ||
                  (space.u_shape_max_capacity !== '' &&
                    space.u_shape_max_capacity !== null) ||
                  (space.cocktail_rounds_max_capacity !== '' &&
                    space.cocktail_rounds_max_capacity !== null) ||
                  (space.banquet_rounds_max_capacity !== '' &&
                    space.banquet_rounds_max_capacity !== null) ||
                  (space.theater_max_capacity !== '' &&
                    space.theater_max_capacity !== null) ||
                  (space.classroom_max_capacity !== '' &&
                    space.classroom_max_capacity !== null) ||
                  (space.boardroom_max_capacity !== '' &&
                    space.boardroom_max_capacity !== null)
                )?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 body-2 font-weight-bold text-uppercase"},[_vm._v(" Sitting Capacities ")]),_c('div',{staticClass:"flex-wrap d-flex ml-n2"},[(
                      space.standing_max_capacity !== '' &&
                      space.standing_max_capacity !== null
                    )?_c('v-card',{staticClass:"ma-2 d-flex",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-standing-capacity")])],1),_c('div',[_vm._v("Standing Capacity")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.standing_max_capacity))+" ")])])],1):_vm._e(),(
                      space.u_shape_max_capacity !== '' &&
                      space.u_shape_max_capacity !== null
                    )?_c('v-card',{staticClass:"ma-2 d-flex",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-u-shape")])],1),_c('div',[_vm._v("U-Shape")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.u_shape_max_capacity))+" ")])])],1):_vm._e(),(
                      space.cocktail_rounds_max_capacity !== '' &&
                      space.cocktail_rounds_max_capacity !== null
                    )?_c('v-card',{staticClass:"ma-2 d-flex",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-cocktail-rounds")])],1),_c('div',[_vm._v("Cocktail rounds")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.cocktail_rounds_max_capacity))+" ")])])],1):_vm._e(),(
                      space.banquet_rounds_max_capacity !== '' &&
                      space.banquet_rounds_max_capacity !== null
                    )?_c('v-card',{staticClass:"ma-2 f-flex",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-banquet-rounds")])],1),_c('div',[_vm._v("Banquet rounds")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.banquet_rounds_max_capacity))+" ")])])],1):_vm._e(),(
                      space.theater_max_capacity !== '' &&
                      space.theater_max_capacity !== null
                    )?_c('v-card',{staticClass:"ma-2 d-flex",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-theater")])],1),_c('div',[_vm._v("Theater")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.theater_max_capacity))+" ")])])],1):_vm._e(),(
                      space.classroom_max_capacity !== '' &&
                      space.classroom_max_capacity !== null
                    )?_c('v-card',{staticClass:"d-flex ma-2",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-classroom")])],1),_c('div',[_vm._v("Classroom")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.classroom_max_capacity))+" ")])])],1):_vm._e(),(
                      space.boardroom_max_capacity !== '' &&
                      space.boardroom_max_capacity !== null
                    )?_c('v-card',{staticClass:"ma-2 d-flex",attrs:{"width":"112","outlined":""}},[_c('v-card-text',{staticClass:"my-auto text-center body-1 text--primary"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mli-boardroom")])],1),_c('div',{staticClass:"my-1"},[_vm._v("Boardroom")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("commaSeparator")(space.boardroom_max_capacity))+" ")])])],1):_vm._e()],1)]):_vm._e(),(space.amenities.length > 0)?_c('v-col',{staticClass:"py-1 my-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 body-2 font-weight-bold text-uppercase"},[_vm._v(" Amenities ")]),_c('div',_vm._l((space.amenities),function(amenity){return _c('v-chip',{key:amenity,staticClass:"px-2 py-0 mx-1 white--text",attrs:{"color":"#A7A7A7","small":"","label":""}},[_vm._v(_vm._s(amenity))])}),1)]):_vm._e()],1)],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }